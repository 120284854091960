<template>
    <b-modal :ref="modalRef" hide-footer @hidden="destroyModal" centered :title="title" no-enforce-focus>
        <!-- no-enforce-focus fixes CKEditor bug when editing links,
             cf https://github.com/ckeditor/ckeditor5/issues/1147 -->
        <div class="mb-4 text-center">
            <balloon-editor v-model="inputValue"/>
        </div>

        <confirm-cancel-buttons @confirm="confirm" @cancel="hideModal"/>
    </b-modal>
</template>

<script>
    import modalMixin from '@/mixin/modalMixin';

    const ConfirmCancelButtons = () => import('@/components/ConfirmCancelButtons');
    const BalloonEditor        = () => import('@/components/BalloonEditor');

    export default {
        name: "EditBalloon",
        components: {ConfirmCancelButtons, BalloonEditor},
        mixins: [modalMixin],
        props: {
            title: {
                type: String,
                default: 'Édition'
            },
            value: {
                type: String,
                default: null
            },
            callback: {
                type: Function,
                required: true
            }
        },
        data: () => ({
            modalRef: 'EditBalloonModal',
            inputValue: null
        }),
        methods: {
            confirm() {
                this.callback(this.inputValue);
            }
        },
        mounted() {
            this.inputValue = this.value;
        }
    }
</script>
